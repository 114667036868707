<template>
  <div class="intro-page">
    <img
      src="https://lesson.iapeap.com/images/cp/001_03.jpg"
      class="intro-container"
      alt=""
    />
    <div class="btn" @click="onbtn"></div>
  </div>
</template>

<script>
export default {
  methods: {
    onbtn() {
      const voteLogId = localStorage.getItem("voteLogId");
      // 把voteLogId传给Report页面
      this.$router.push({
        path: "/CP/minsheng/Report",
        query: { voteLogId },
      });
    },
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eafaf7;
}

.intro-container {
  width: 100%;
  max-width: 400px;
}
.btn {
  width: 50%;
  height: 250px;
  position: absolute;
  top: 15%;
  left: 25%;
  /* 小手按钮 */
  cursor: pointer;
}
</style>
